import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { PodcastsResponse } from '@pocketbase-types';

// export const podcastDefault: PodcastDetail = {
//   id: 'ywgiyxxxjvsriii',
//   title: 'Gutup Foodie',
//   subtitle: 'Le podcast qui te veut du bien!',
//   description: `
//     <div>
//       Tu rêves d’améliorer ta santé physique et mentale sans te ruiner? Moi aussi! Donc j’ai lancé ma chaîne podcast sur YouTube et Spotify.
//       <br><br>
//       Pourquoi cette chaîne? Parce que je crois que de petits ajustements peuvent transformer nos vies. J’invite des pros de la santé pour te livrer leurs meilleurs conseils. Pas besoin de payer une consultation, tout se passe ici, à mon micro!
//       <br><br>
//       Au programme:
//       <br>
//       <ul class="list-disc pl-8">
//         <li>Des astuces santé accessibles</li>
//         <li>Des conseils pour être bien dans ta peau et ta tête</li>
//         <li>Des discussions inspirantes avec des experts</li>
//       </ul>
//       <br>
//        Abonne-toi maintenant à ma chaîne Youtube et active la cloche de notification pour ne jamais manquer une nouvelle vidéo!
//            <br><br>
//       Fais partie de notre communauté bienveillante et partage tes pensées en commentaires.
//     </div>
//   `,
//   spotifyLink:
//     'https://open.spotify.com/episode/6344s6P5g9pNirdHoSJEsu?si=o4qLJqzNSpqulA5zQhN0WQ',
//   youtubeLink: 'https://youtu.be/voKg5D6-YNY?si=hnsfllEwB6X0ZG6W',
//   speaker: {
//     name: 'Elise Garcia',
//     title: 'Épicurienne',
//     bio: `
//       <div>
//         Je suis la fondatrice de GutUp et je me réjouis de te compter dans la communauté!
//         <br><br>
//         Si tu veux en apprendre plus sur mon quotidien, suis mes aventures sur Instagram <strong>@gutup_foodie</strong>.
//         <br><br>
//         <div class="flex content-center items-center">
//           Prends soin de toi.
//           <img class="pl-4" alt="Signature d'Elise" src="/gutup/podcast/podcast1/elise-signature.svg"
//         </div>
//       </div>
//     `,
//     imageUrl: '/gutup/podcast/podcast1/podcast-portrait-elise.png',
//     buttonText: 'Suis-moi sur Instagram',
//     buttonLink: 'https://www.instagram.com/gutup_foodie/',
//   },
//   headerImageUrl: '/gutup/podcast/podcast1/podcast-about-elise.png',
//   miniatureImageUrl: '/gutup/podcast/podcast-list/podcast-elise-miniature.png',
// };
// export const podcasts: PodcastDetail[] = [
//   podcastDefault,
//   {
//     id: '9elkaelu8d5x6ry',
//     title: 'Alimentation anti&#8209;inflammatoire',
//     subtitle: 'Le pouvoir cache de ton assiette',
//     description: `
//     <div>
//       Prends un moment pour explorer comment ton alimentation peut transformer ta santé et ton bien-être. Dans ce podcast, on aborde l'alimentation anti-inflammatoire en compagnie de Déborah, Diététicienne spécialisée en Thérapie Cognitive Comportementale, travaillant au Centre Métabolique Nutrition et Mouvement à Carouge.
//       <br><br>
//       Au fil de cette conversation, tu vas :
//       <br>
//       <ul class="list-disc pl-8">
//         <li>Comprendre les bases de l'alimentation anti-inflammatoire et ses principes, souvent proches de la cuisine méditerranéenne.</li>
//         <li>Découvrir quels aliments intégrer pour bénéficier des oméga-3, fibres, vitamines et antioxydants.</li>
//         <li>Apprendre les types de cuisson qui préservent les nutriments essentiels.</li>
//         <li>Identifier ce qu'il faut éviter pour réduire l'inflammation</li>
//         <li>Partager des idées de repas et de collations pour intégrer facilement ces principes dans ton quotidien.</li>
//       </ul>
//       <br>
//       Ce podcast est une invitation à comprendre comment de simples changements dans ton alimentation peuvent avoir un impact profond sur ta santé.
//       <br><br>
//       Bonne écoute!
//       <br><br>
//     </div>
//   `,
//     spotifyLink:
//       'https://open.spotify.com/episode/3WsVzSbiqE5Wds3McRfX1b?si=1RlUF3mOTXSzPJU7dAxnDA',
//     youtubeLink: 'https://youtu.be/F6Hmg6LNYXI?si=o70aBvA2Il9sMLpY',
//     speaker: {
//       name: 'Deborah Haesslein',
//       title: 'Diététicienne ASDD',
//       bio: `
//       <div>
//         Diplômée en 2008 de la Haute École de Santé (HES), je suis Diététicienne, membre de l'Association Suisse des Diététicien(ne)s diplômé(e)s (ASDD) au Centre Métabolique Nutrition et Mouvement à Carouge.
//         <br><br>
//         Mon approche est globale et personnalisée. Je prends en compte l'environnement familial, la culture, les expériences de vie, le cadre de travail, et les sensations digestives de mes patients pour leur offrir des conseils adaptés et durables. En utilisant des techniques d'entretien motivationnel, je soutiens les motivations de chacun pour les aider à persévérer. Mon approche cognitivo-comportementale permet d'identifier et de travailler sur les déclencheurs des comportements alimentaires. Je crois fermement que l'acte alimentaire reflète l'éducation, les principes, la culture et les expériences de vie de chacun.
//         <br><br>
//         Passionnée de cuisine italienne, j'adore partager des repas conviviaux avec mes proches. Je pratique également la course à pied et le fitness. Je peux mener des consultations en français et en anglais.
//         <br><br>
//       </div>
//     `,
//       imageUrl: '/gutup/podcast/podcast2/podcast-portrait-deb.png',
//       buttonText: '',
//       buttonLink: '',
//     },
//     headerImageUrl: '/gutup/podcast/podcast2/podcast-about-deb.png',
//     miniatureImageUrl: '/gutup/podcast/podcast-list/podcast-deb-miniature.png',
//   },
//   {
//     id: 'n390okupvnhjm0e',
//     headerImageUrl: '/gutup/podcast/podcast3/podcast-about-melanie.png',
//     title: 'Microbiote Revolution',
//     subtitle: 'Ton intestin influence ton quotidien!',
//     description: `
//        Plonge avec nous dans un voyage au cœur de l'univers invisible qui régit notre santé: le microbiote intestinal!
//        <br><br>
//        Dans cet épisode, on te propose de découvrir l'incroyable pouvoir des milliards de micro-organismes qui peuplent nos intestins.
//        Guidés par Mélanie, pharmacienne experte en micronutrition, on va démystifier ce microcosme et comprendre pourquoi il est unique à chacun d'entre nous.
//        <br><br>
//        Tu te demandes ce qu’est réellement le microbiote intestinal? Pourquoi il évolue au fil du temps, et surtout, comment il influence directement notre santé?
//        <br>
//        Mélanie va t’éclairer sur ses fonctions vitales: digestion, production de vitamines, régulation de l'inflammation, et défense immunitaire… Tu verras, le microbiote est un acteur clé de notre bien-être global. Mais attention, lorsqu’il se déséquilibre, c’est la porte ouverte à une série de problèmes de santé: maladies métaboliques, auto-immunes, et même troubles mentaux peuvent en découler.
//        <br><br>
//        On te partage des conseils concrets pour chouchouter ton microbiote: alimentation adaptée, prébiotiques, probiotiques, tout y passe!`,
//     spotifyLink:
//       'https://open.spotify.com/episode/3OGOVLKwTAwblKLUbPbqhO?si=a5cb2cccf4154e21',
//     youtubeLink: 'https://youtu.be/KZByTHOFqFk?si=CSx-bSWXpOQLv04U',
//     speaker: {
//       name: 'Melanie Hennequin Levrier',
//       title: 'Pharmacienne et Nutritionniste',
//       bio: `
//           Pharmacienne nutritionniste spécialisée en micronutrition, j’aide mes patient·es à mieux comprendre leur organisme et ses réactions afin de répondre à leur problématique du type fatigue chronique (troubles du sommeil, gestion du stress, etc.), troubles digestifs (ballonnements, constipation, diarrhées), ou accompagnement de perte de poids.
//           <br><br>
//           Avec bienveillance, je vous propose des solutions personnalisées pour corriger les déséquilibres avec l’aide de l’alimentation et différents outils (les compléments alimentaires, la phytothérapie, l’action sur le microbiote). Ensemble, nous travaillons à votre rythme pour retrouver une alimentation et une hygiène de vie équilibrée qui vous permettront d’améliorer votre qualité de vie.`,
//       imageUrl: '/gutup/podcast/podcast3/podcast-portrait-melanie.png',
//       buttonText: '',
//       buttonLink: '',
//     },
//     miniatureImageUrl:
//       '/gutup/podcast/podcast-list/podcast-melanie-miniature.png',
//   },
// ];

export const podcastResolver: ResolveFn<PodcastsResponse | undefined> = async (
  route,
) => {
  const slug = route.paramMap.get('id')!;
  // Extract ID from the slug (assuming it ends with `-id`)
  const id = slug.split('-').pop(); // Gets the last part of the slug

  return inject(DataService)
    .getPodcasts()
    .find((i) => i.id === id);
};
