import { Component, inject, input } from '@angular/core';
import { GutupPodcastBubbleCardComponent } from '../../components/gutup-bubbles/gutup-podcast-bubble-card.component';
import { RouterLink } from '@angular/router';
import { NgOptimizedImage } from '@angular/common';
import { RoutingService } from '../../services/routing.service';
import { PodcastsResponse } from '@pocketbase-types';

@Component({
  selector: 'app-profile-podcast-bubble',
  template: ` <div class="flex min-w-[250px] flex-col items-center">
    <div class="relative">
      <h1 class="absolute left-[6%] top-[5%] font-vinasans text-4xl">
        MON PODCAST
      </h1>
      <img
        [ngSrc]="podcast().miniatureImage"
        [alt]="podcast().title"
        (click)="navigateToPodcastId('1')"
        class="cursor-pointer"
        width="300"
        height="300"
      />
    </div>
  </div>`,
  standalone: true,
  imports: [GutupPodcastBubbleCardComponent, RouterLink, NgOptimizedImage],
})
export class ProfilePodcastBubbleComponent {
  podcast = input.required<PodcastsResponse>();
  #routingService = inject(RoutingService);

  navigateToPodcastId(id: string) {
    this.#routingService.navigate([`/podcast/${id}`], { scrollTop: true });
  }
}
