import { Component, inject, signal } from '@angular/core';
import { RouterLink } from '@angular/router';
import { RoutingService } from '../../../services/routing.service';
import { NgOptimizedImage } from '@angular/common';
import { sanitizeSlug } from '../../../../scripts/sanitize-slug';
import { PodcastsResponse } from '@pocketbase-types';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'app-gutup-podcast-list',
  standalone: true,
  imports: [RouterLink, NgOptimizedImage],
  template: `
    <div class="flex space-x-4 overflow-x-auto py-4 max-md:w-[100vw]">
      @for (podcast of podcasts(); track podcast.id) {
        <div class="flex-shrink-0">
          <img
            [ngSrc]="podcast.miniatureImage"
            [alt]="podcast.title"
            (click)="navigateToPodcast(podcast)"
            class="cursor-pointer"
            width="300"
            height="300"
          />
        </div>
      }
    </div>
  `,
})
export class GutupPodcastListComponent {
  podcasts = signal<PodcastsResponse[]>([]);

  #dataService = inject(DataService);
  #routingService = inject(RoutingService);

  constructor() {
    this.podcasts.set(this.#dataService.getPodcasts());
  }

  navigateToPodcast(podcast: PodcastsResponse) {
    const slug = `${sanitizeSlug(podcast.title)}-${podcast.id}`;
    this.#routingService.navigate([`/podcast/${slug}`], { scrollTop: true });
  }
}
