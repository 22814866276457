import { Component, computed, inject, input } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { GutupSimpleBubbleCardComponent } from '../../components/gutup-bubbles/gutup-simple-bubble-card.component';
import { GutupBubbleCardComponent } from '../../components/gutup-bubbles/gutup-bubble-card.component';
import { ProfilesResponse } from '@pocketbase-types';
import { ProfileEmptyPodcastBubbleComponent } from './profile-empty-podcast-bubble.component';
import { ProfileBubbleCardComponent } from './profile-bubble-card.component';
import FaqItemComponent from './faq-item.component';
import { ProfileFaqBubbleCardComponent } from './profile-faq-bubble-card.component';
import { RouterLink } from '@angular/router';
import { ProfilePodcastBubbleComponent } from './profile-podcast-bubble.component';
import { RemoveDiacriticsPipe } from './diacritics.pipe';
import ProfileFaqComponent from './profile-faq.component';
import { DataService } from '../../services/data.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    GutupSimpleBubbleCardComponent,
    NgOptimizedImage,
    GutupBubbleCardComponent,
    ProfileEmptyPodcastBubbleComponent,
    ProfileBubbleCardComponent,
    FaqItemComponent,
    ProfileFaqBubbleCardComponent,
    RouterLink,
    ProfilePodcastBubbleComponent,
    RemoveDiacriticsPipe,
    ProfileFaqComponent,
  ],
  template: `
    @if (profile()) {
      <div class="md:mx-[10%]">
        <!-- Header -->
        <div class="pb-20 md:pb-12">
          <div
            class="flex flex-col items-center space-y-4 md:flex-row md:space-y-0"
          >
            <!-- Profile picture -->
            <div
              class="min-w-[300px] max-w-[250px] md:mr-12 md:flex md:w-1/3 md:max-w-full md:flex-col md:justify-center"
            >
              <img
                [ngSrc]="profilePicture()"
                [alt]="profile().first_name"
                height="400"
                width="400"
              />
            </div>

            <!--  Name and title -->
            <div class="flex flex-col justify-center md:w-1/3">
              <div class="space-y-2 text-center md:text-left">
                <div class="font-vinasans text-4xl">
                  {{ profile().first_name | removeDiacritics }}
                  {{ profile().last_name | removeDiacritics }}
                </div>
                <div class="text-center text-xl md:text-left md:text-2xl">
                  {{ profile().title }}
                </div>
              </div>
            </div>

            <!-- Profile podcasts -->
            @if (podcast()) {
              <div class="flex justify-center md:w-1/3">
                <app-profile-podcast-bubble [podcast]="podcast()!" />
              </div>
            } @else {
              <div class="flex justify-center md:w-1/3">
                <app-profile-empty-podcast-bubble />
              </div>
            }
          </div>
        </div>

        <!-- Content for bubbles -->
        <div class="mx-[5%] space-y-16">
          @if (profile().description) {
            <div>
              <app-profile-bubble-card title="Qui suis-je ?">
                <div [innerHTML]="profile().description"></div>
              </app-profile-bubble-card>
            </div>
          }

          @if (profile().address_text) {
            <div>
              <app-profile-bubble-card title="Me trouver">
                <div class="flex flex-col space-y-2">
                  <div>Je consulte au:</div>
                  <div class="flex items-center">
                    <img
                      class="mr-2 w-8"
                      ngSrc="/gutup/profile/map.png"
                      alt="Petit icon d'une carte dépliante"
                      height="97"
                      width="116"
                    />
                    @if (profile().address_link) {
                      <a
                        [href]="profile().address_link"
                        class="underline"
                        target="_blank"
                      >
                        {{ profile().address_text }}
                      </a>
                    } @else {
                      {{ profile().address_text }}
                    }
                  </div>
                </div>
              </app-profile-bubble-card>
            </div>
          }

          @if (profile().website) {
            <div>
              <app-profile-bubble-card title="Me Contacter">
                <div class="flex-col">
                  <div>Vous pouvez me contacter par ici:</div>
                  <div
                    class="flex flex-col justify-center space-y-1 md:flex-row md:space-x-4 md:space-y-0"
                  >
                    @if (profile().phone_number) {
                      <div class="flex items-center">
                        <img
                          class="mr-2 w-6"
                          src="/gutup/profile/phone.png"
                          alt="Petit icon d'un telephone"
                        />
                        <a
                          [href]="'tel:' + profile().phone_number"
                          class="underline"
                        >
                          {{ profile().phone_number }}
                        </a>
                      </div>
                    }

                    @if (profile().website) {
                      <div class="flex items-center">
                        <img
                          class="mr-2 w-7"
                          src="/gutup/profile/web.png"
                          alt="Petit icon du web"
                        />
                        <a [href]="profile().website" class="underline">
                          {{ profile().website }}
                        </a>
                      </div>
                    }
                  </div>
                </div>
              </app-profile-bubble-card>
            </div>
          }

          <div>
            <app-profile-faq [profile]="profile()" />
          </div>

          <div
            routerLink="/contact"
            class="flex cursor-pointer items-center justify-center text-center font-vinasans text-3xl text-gutup-orange md:space-x-14 md:text-4xl"
          >
            <img
              ngSrc="/gutup/cursor-green.svg"
              alt="Cursor"
              class="w-12"
              height="27"
              width="33"
            />
            <div>Je veux modifier ou supprimer mon profil</div>
            <img
              ngSrc="/gutup/cursor-green.svg"
              alt="Cursor"
              class="w-12"
              height="27"
              width="33"
            />
          </div>
        </div>
      </div>
    } @else {
      <p>Unable to load profile. Please try again later.</p>
    }
  `,
})
export class ProfileComponent {
  #dataService = inject(DataService);
  profile = input.required<ProfilesResponse>();
  podcast = computed(() =>
    this.#dataService
      .getPodcasts()
      .find((p) => p.id === this.profile().podcastId),
  );
  profilePicture = computed(() => {
    if (this.profile().profile_picture === '') {
      return '/gutup/profile/default-profile-picture.png';
    }
    return this.profile().profile_picture;
  });
}
