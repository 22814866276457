import { Component, computed, inject, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { RoutingService } from '../../../services/routing.service';
import { GutupPodcastListComponent } from '../gutup-podcast-list/gutup-podcast-list.component';
import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { GutupBubbleCardComponent } from '../../../components/gutup-bubbles/gutup-bubble-card.component';
import { PodcastHeaderComponent } from './podcast-header.component';
import { PodcastSpeakerComponent } from './podcast-speaker.component';
import { PodcastListComponent } from './podcast-list.component';
import { PodcastSharedLinksComponent } from './podcast-links.component';
import { PodcastDescriptionComponent } from './podcast-description.component';
import NewsletterFormComponent from '../../../components/newsletter-form.component';
import { PodcastsResponse } from '@pocketbase-types';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'app-podcast-detail',
  standalone: true,
  imports: [
    RouterLink,
    GutupPodcastListComponent,
    AsyncPipe,
    NgOptimizedImage,
    GutupBubbleCardComponent,
    PodcastHeaderComponent,
    PodcastDescriptionComponent,
    PodcastSharedLinksComponent,
    PodcastSpeakerComponent,
    PodcastListComponent,
    NewsletterFormComponent,
  ],
  template: `
    @if (podcast()) {
      <div class="gutup-container max-w-screen-2xl">
        <div class="space-y-6 md:space-y-24">
          <div>
            <app-podcast-header [podcastDetail]="podcast()" />
          </div>

          <div class="flex justify-center">
            <app-podcast-description [description]="podcast().description" />
          </div>

          <div>
            <app-podcast-shared-links
              [spotifyLink]="podcast().spotifyLink"
              [youtubeLink]="podcast().youtubeLink"
            />
          </div>

          <div class="flex justify-center">
            @if (speaker()) {
              <app-podcast-speaker [speaker]="speaker()!" />
            }
          </div>

          <div class="py-12">
            <app-bubble-card
              [hasTitle]="true"
              [backgroundName]="'bg-green-bubble'"
            >
              <app-newsletter-hubspot-form class="w-full" />
            </app-bubble-card>
          </div>

          <div>
            <app-podcast-list (navigateToPodcast)="navigateToPodcast()" />
          </div>
        </div>
      </div>
    }
  `,
})
export class PodcastDetailComponent {
  podcast = input.required<PodcastsResponse>();

  #routingService = inject(RoutingService);
  #dataService = inject(DataService);

  speaker = computed(() => {
    return this.#dataService.getProfileById(this.podcast().speaker);
  });

  navigateToPodcast() {
    this.#routingService.navigate(['/podcast'], { scrollTop: true });
  }
}
