import { inject, Injectable } from '@angular/core';
import {
  AlimentsResponse,
  LanguagesResponse,
  PodcastsResponse,
  ProfilesResponse,
  RecettesResponse,
} from '@pocketbase-types';
import { Data, DATA_PROVIDER } from './data-provider';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private readonly dataProvider = inject(DATA_PROVIDER);
  private data: Data | null = null;

  async loadData(): Promise<void> {
    try {
      this.data = await this.dataProvider.getData();
    } catch (error) {
      console.error('Error loading data:', error);
      this.data = null;
    }
  }

  getFoods(): AlimentsResponse[] {
    return (this.data?.aliments as AlimentsResponse[]) ?? [];
  }

  getRecipes(): RecettesResponse[] {
    return (this.data?.recettes as RecettesResponse[]) ?? [];
  }

  getProfiles(): ProfilesResponse[] {
    const profiles = (this.data?.profiles as ProfilesResponse[]) ?? [];
    return sortProfiles(profiles);
  }

  getProfileById(id: string): ProfilesResponse | undefined {
    return this.getProfiles().find((profile) => profile.id === id);
  }

  getLanguages(): LanguagesResponse[] {
    return (this.data?.languages as LanguagesResponse[]) ?? [];
  }

  getPodcasts(): PodcastsResponse[] {
    return (this.data?.podcasts as PodcastsResponse[]) ?? [];
  }
}

function sortProfiles(profiles: ProfilesResponse[]): ProfilesResponse[] {
  return profiles.sort((a, b) => {
    if (a.last_name < b.last_name) return -1;
    if (a.last_name > b.last_name) return 1;
    return 0;
  });
}
